import React, { useState } from 'react'
import SourceIcon from '@mui/icons-material/Source';
import { TextField, Typography, Grid} from '@mui/material' 
import {sendDataToServer, arrayChunk} from '../functions'
import { Link } from 'react-router-dom';

export default function EpgNameSearch(props){

    const [data, setData] = useState([])

    var s;
    function handleChange(e){
        clearTimeout(s);
        if(e.target.value.length > 0){
            s = setTimeout(function(){
                sendDataToServer({'op': "select", 'table': "names", 'cols':"*", where : "chname LIKE '"+e.target.value+"%' ORDER BY chname"})
                .then(r => {
                    setData(arrayChunk(r, Math.ceil(r.length / 4)))
                })
            },500)
        }
    }





    return(
        <>
            <Typography variant="h5"><SourceIcon /> EPG Name Search</Typography>
            <TextField id="outlined-basic" label="Outlined" variant="outlined" style={{marginBottom: "20px"}} placeholder="Введите текст" onChange={handleChange} />
            <Grid container spacing={2}>
            {
                    data.map((vv, kk) => (
                        <Grid item xs={3} key={kk}>
                        {
                        vv.map((v, k) => (
                            <><Link to={v['id']} className={"a-chname "+(v['cntprg']>0?"":"ds")} key={v['id']}>{v['chname']} [{v['cntprg']}]</Link></>
                        ))
                        }  
                        </Grid>
                    ))
            }
            </Grid>
            <hr />
        </>
    )
}