import React, { useState } from 'react'
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button} from "@mui/material"





export default function ModalForm({mode, data, onClick, title}){

    const [open, setOpen] = useState(false)


    const [formData, setFormData] = useState(data);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
          ...formData,
          [name]: value,
        })
    }

    const handleSubmit = () => {
        onClick({...formData})
        setOpen(false)
    };

    function handleClose(){
        setOpen(false)
    }
    function handleOpen(){
        setOpen(true)
    }


    return (
        <>

        <Button onClick={handleOpen} >{mode}</Button>
        
        <Dialog open={open} >
                <DialogTitle>
                    {mode==="add" ? " Add new" : " Edit"}
                </DialogTitle>
            <DialogContent>

            <DialogContentText>
                {title}
            </DialogContentText>

            <form>
                {
                    Object.keys(data).map((k) => (
                        <TextField
                            label={k}
                            key={k}
                            name={k}
                            value={formData[k]}
                            fullWidth
                            onChange={handleChange}
                            margin="dense"
                            disabled={k==='id' ? true : false}
                        />
                    ))
                }
            </form>

            </DialogContent>
            <DialogActions>
            <Button onClick={handleSubmit} color="primary" autoFocus>
                {mode}
            </Button>
            <Button onClick={handleClose} color="secondary">
                cancel
            </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}