import { Typography } from '@mui/material';
import axios from 'axios'


export const sendDataToServer = async (data) => {
    try {
      const response = await axios.post('https://epgapi.com/api', data);
      var d = response.data
      if(d.status === "DATA"){
        return d['data']
      }

      return d
      
    } catch (error) {
      console.error('Error request data:', error);
    }
  }; 






export  function arrayChunk(arr, chunkSize) {
    const chunkedArray = [];
    
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
}







export  function convUnixDate(unixTime){
    const date = new Date(unixTime * 1000)
    const dateNow = new Date()
    const timeLast = (dateNow.getTime()/1000)-86400
    const dateLast = new Date(timeLast * 1000)
    const Y = date.getFullYear();
    let m = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    let d = date.getDate();
    let H = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();
    var ddate = ""
    if(dateNow.getFullYear() === Y &&
            (dateNow.getMonth()+1) === m &&
                    dateNow.getDate() === d){
            ddate = "Today"
        }else if(dateLast.getFullYear() === Y &&
                    (dateLast.getMonth()+1) === m &&
                        dateLast.getDate() === d){
             ddate = "Yesterday"
        }else{
          if(m<10) m = "0"+m
          if(d<10) d = "0"+d
          ddate = d+"."+m+"."+Y
        }


        if(H<10) H = "0"+H
        if(i<10) i = "0"+i
        if(s<10) s = "0"+s

    return(
        <><Typography variant="code">{ddate}</Typography> {H}:{i}:{s}</>
    )
}



