import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import {sendDataToServer, convUnixDate} from '../functions'


export default function EpgId(){

    const { id } = useParams();
    const [src, setSrc] = useState([])
    const [data, setData] = useState([])


    useEffect(() => {
        sendDataToServer({'op': "select", 'table': "names", 'cols':"*", where : "id="+id})
        .then(r => {
            setSrc(r[0]) 
            
            

            sendDataToServer({'op': "select", 'table': "program", 'cols':"*", where : "chid='"+r[0]['chid']+"' AND srcid='"+r[0]['srcid']+"' ORDER BY start"})
            .then(req => {
                setData(req) 
                //console.log(req)
            })
        })
    // eslint-disable-next-line
    }, [id])


    return (
        <>
            <h3><img width="50px" src={src['icon']} alt='' /> {src['chname']} <code style={{backgroundColor:'#ffff00'}}>{src['srcid']}:{src['chid']}</code></h3>
            <table border={1}><tbody>
            {
                data.map((v, k) => (
                    <tr className="pro" key={k}><td><b>{convUnixDate(v['start'])}</b></td><td>{v['title']}</td></tr>
                ))
            }
            </tbody></table>
        </>
    )
}