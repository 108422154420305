import React, { useEffect, useState } from 'react'
import { sendDataToServer, arrayChunk } from '../functions';
import { Typography} from '@mui/material' 
import SourceIcon from '@mui/icons-material/Source';
import ChannelsItems from './ChannelsItems'
import ChannelsSearch from './ChannelsSearch'





export default function ChannelsMain(props){

  const [data, setData] = useState([])



  useEffect(() => {
    sendDataToServer({'op': "select", 'table': "names", 'cols':"*", where : "id>0 ORDER BY chname"})
    .then(r => {
        setData(arrayChunk(r, Math.ceil(r.length / 6)))
    })
  }, [])


    return(
        <>

            <Typography variant="h5"><SourceIcon /> EPG channels</Typography>
            <ChannelsSearch retData={(d) => setData(d)}/>            
            <ChannelsItems data={data} />


        </>
    )
}

