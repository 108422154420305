import React, { useState } from 'react'
import { sendDataToServer, arrayChunk } from '../functions';
import { TextField} from '@mui/material' 





export default function ChannelsMain({retData}){

  //const [data, setData] = useState([])
  const [timer, setTimer] = useState(0)


 
  function handleChange(e){
       clearTimeout(timer);
       if(e.target.value.length > 0){
           retData([])
           const t = setTimeout(function(){
               sendDataToServer({'op': "select", 'table': "names", 'cols':"*", where : "chname ILIKE '%"+e.target.value+"%' ORDER BY chname"})
               .then(r => {
                    retData(arrayChunk(r, Math.ceil(r.length / 6)))
               })
           },500)
           setTimer(t) 
       }
  }


    return(
        <>
            <TextField id="outlined-basic" label="Outlined" variant="outlined" style={{marginBottom: "20px", width:"50%"}} placeholder="Начните набирать название канала" onChange={handleChange} />
        </>
    )
}

