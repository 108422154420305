import React from 'react'
import EpgSources from '../components/epg/EpgSources'
import EpgNameSearch from '../components/epg/EpgNameSearch'

export default function EpgPage(){

    return(
        <>  
        <EpgNameSearch />
        <EpgSources />
        </>
    )
}