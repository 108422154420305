import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage'
import StreamPage from '../pages/StreamPage'
import FilePage from '../pages/FilePage'
import HelpPage from '../pages/HelpPage'
import TunnelPage from '../pages/TunnelPage'
import SettingsPage from '../pages/SettingsPage'
import LogoutPage from '../pages/LogoutPage'
import NotFoundPage from '../pages/NotFoundPage'
import EpgPage from '../pages/EpgPage'
import SourcesPage from '../pages/SourcesPage'
import EpgId from '../components/epg/EpgId'


export default function RoutesUser(props){

    return(
        <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/sources' element={<SourcesPage />} />
        <Route path='/epg/:id' element={<EpgId />} />
        <Route path='/epg' element={<EpgPage />} />
        <Route path='/stream' element={<StreamPage />} />
        <Route path='/file' element={<FilePage />} />
        <Route path='/tunnel' element={<TunnelPage />} />
        <Route path='/help' element={<HelpPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='/logout' element={<LogoutPage />} />
        <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    );
}
