import './App.css';
import * as React from 'react';
import { Box, Container } from '@mui/material';
import NavBar from './components/NavBar';
import Cookies from 'js-cookie';
import RoutesUser from './components/RoutesUser'
import RoutesGuest from './components/RoutesGuest'









function App() {

  const user = Cookies.get('user');

  return (
    <>

    {user ? <NavBar /> : ""}

    <Container maxWidth="xl">
      <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 2,
                borderRadius: 2,
                p: 3,
                minWidth: 300,
                mt:3
          }}>
          
            {(user) ? <RoutesUser /> : <RoutesGuest />}
          
        </Box>

        <Box className="bg-grad" sx={{
              // bgcolor: '#a3aab1',
              boxShadow: 0,
              borderRadius: 2,
              p: 2,
              minWidth: 300,
              mt:2,
              textAlign:'center',
              fontSize:'12px',
              color:'text.secondary'
        }}>&copy;2023 Copyright <a href="https://t.me/unidiag" target="_blank" rel="noreferrer">@unidiag</a>. All rights reserved.</Box>
    </Container>



    </>
  );
}

export default App;
