import { Link } from '@mui/material'
import React, { useState } from 'react'
import {sendDataToServer} from '../functions'

export default function EpgSrcStatus({status, id}){
    const [state, setState] = useState(status)

    function handleClick(){
        if(state){
            setState(0)
        }else{
            setState(1)
        }
        sendDataToServer({op: "update", table: "sources", status: (state?0:1), where: "id="+id}).then(r => {
            console.log(state, r)
        })
    }

    return (
        <Link to="#" onClick={handleClick} className="epg-src-status">{state}</Link>
    )
}