import React, { useState } from "react";
import { FormControl, Button, TextField, Grid, Typography } from "@mui/material";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const LoginForm = ({ onSubmit }) => {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
  
    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit({ user, password });
      setUser(''); setPassword('');
    };

    const handleKeyPress = (e) => {
      if(e.key==="Enter"){
        onSubmit({ user, password });
        setUser(''); setPassword('');
      }
    }
  
    return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        <VpnKeyIcon />
        <Typography>Enter your Login/Password</Typography>
        <FormControl>
          <TextField
            label="Login"
            value={user}
            onChange={(event) => setUser(event.target.value)}
            required
            style={{ marginTop:"20px"}}
          />
          <TextField
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(handleKeyPress)}
            required
            variant="outlined"
            type="password"
            style={{ marginTop:"20px"}}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop:"30px"}}
          >
            Войти
          </Button>
        </FormControl>
        </Grid>
    </Grid>
    );
  };
  
  export default LoginForm;