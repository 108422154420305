import React, {useState} from 'react'
import {Tooltip, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button} from "@mui/material"
import CastIcon from '@mui/icons-material/Cast'
import {sendDataToServer} from '../functions'
import AddIcon from '@mui/icons-material/Add'
 


export default function StreamsForm({mode, flagUpdate}){

    const [open, setOpen] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        input: '',
        output: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
    const handleSubmit = () => {
        sendDataToServer({op:"insert", table:"streams", time_create: "datetime('now')", ...formData}).then(
            setFormData({name: '',input: '',output: ''})
        )
        flagUpdate()
    };

    function handleClose(){
        setOpen(false)
    }
    function handleOpen(){
        setOpen(true)
    }

    return(

        <>
            {  (mode === "add" ?
                <Tooltip title="Add new" arrow>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            minWidth: 'auto',
                            padding: 0,
                        }}
                        onClick={handleOpen}
                        >
                        <AddIcon />
                    </Button>
                </Tooltip>
                :
                <Tooltip title="Edit" arrow>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            minWidth: 'auto',
                            padding: 0,
                        }}
                        onClick={handleOpen}
                        >
                        <AddIcon />
                    </Button>
                </Tooltip>
                )
            }
            

            <Dialog open={open} >
                <DialogTitle><CastIcon style={{position:"relative", top:"3px"}} />
                    {mode==="add" ? " Add new STREAMs" : " Edit stream"}
                </DialogTitle>
            <DialogContent>

            <DialogContentText>
                { mode==="add" ? "Give your stream a name and assign an address where to get it from." : "Edit your stream.." }
            </DialogContentText>

            <form>
                <TextField
                label="Name stream"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="dense"
                autoFocus
                />
                <TextField
                label="Input"
                name="input"
                value={formData.input}
                onChange={handleChange}
                fullWidth
                margin="dense"
                />
                <TextField
                label="Output"
                name="output"
                value={formData.output}
                onChange={handleChange}
                fullWidth
                margin="dense"
                />
            </form>

            </DialogContent>
            <DialogActions>
            <Button onClick={handleSubmit} color="primary" autoFocus>
                {mode==="add" ? "+ Add" : "Edit"}
            </Button>
            <Button onClick={handleClose} color="secondary">
                Cancel
            </Button>
            </DialogActions>
        </Dialog>
            
            </>

    )
}