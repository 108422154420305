import React from 'react'
import LoginForm from '../components/LoginForm'
import Cookies from 'js-cookie';
import { sendDataToServer } from '../components/functions';



export default function LoginPage(props){


    return(
        <>
        <LoginForm onSubmit={(data) => {
            const newdata = {...data, 'op': "login"}
            sendDataToServer(newdata).then(r => {
                if(r.error){
                    alert(r.error)
                }else{
                    Cookies.set('user', r.user, { expires: 30 })
                    Cookies.set('rank', r.rank, { expires: 30 })
                    Cookies.set('hash', r.hash, { expires: 30 })
                    window.location.href=""
                }
            })
        }} />


        </>
    )
}