import React from 'react'
import { Link } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { Grid} from '@mui/material' 


export default function ChannelsItems({data}){


    return (
        <>
            {
                data.length===0 ? (
                <div className="loader-center"><Circles
                    visible={true}
                    height="80"
                    width="80"
                    color="#1976d2"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                /></div> ) : (
                
                <Grid container spacing={2}>
                {
                        data.map((vv, kk) => (
                            <Grid item xs={2} key={kk+Math.random()}>
                            {
                            vv.map((v) => (
                                <div key={v['id']}>
                                    <Link to={"/epg/"+v['id']} className={"a-chname "+(v['cntprg']>0?"":"ds")}>{v['chname']} [{v['cntprg']}]</Link>
                                </div>
                            ))
                            }  
                            </Grid>
                        ))
                }
                </Grid>
                )
            }
        </>
    )
}
