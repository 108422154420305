import React, { useEffect, useState } from 'react'
import {sendDataToServer, convUnixDate} from '../functions'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Typography} from '@mui/material'
import SourceIcon from '@mui/icons-material/Source';
import EpgSrcUpdateButton from './EpgSrcUpdateButton'
import EpgSrcStatus from './EpgSrcStatus'

export default function EpgSources(){
    const [src, setSrc] = useState([])

    useEffect(() => {
        sendDataToServer({'op': "select", 'table': "sources", 'cols':"*", where: "id>0 ORDER BY status DESC"})
        .then(r => {
            setSrc(r)
            //console.log(r);
        })
    }, [])









    return(
        <>
            <Typography variant="h5"><SourceIcon /> EPG Sources</Typography>
            <TableContainer component={Paper}>
                <Table  size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>#id</TableCell>
                        <TableCell>url</TableCell>
                        <TableCell>descr</TableCell>
                        <TableCell>update</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        src.map((v, k) => 
                            <TableRow key={k} className="nopadding-td">
                                <TableCell>{v.id}</TableCell>
                                <TableCell>{v.url}</TableCell>
                                <TableCell>{v.descr}</TableCell>
                                <TableCell>{convUnixDate(v.update)}</TableCell>
                                <TableCell><EpgSrcStatus status={v.status} id={v.id} /></TableCell>
                                <TableCell><EpgSrcUpdateButton id={v.id} /></TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


