import React, { useState } from 'react'
import {Button} from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached';
import { sendDataToServer } from '../functions';
import "../../App.css"

export default function EpgSrcUpdateButton({ id }){

    const [reload, setReload] = useState(false) 

    function handleClick(){
        sendDataToServer({'op': "epgSrcUpdate", 'id': id, 'start':reload?false:true})
        .then(r => {
            console.log(r);
            setReload(reload?false:true)
        }) 
    }

    return (
        <>
            <Button onClick={handleClick}><CachedIcon className={reload?"spinner":""} /></Button>
        </>
    )
}

