import { TextField } from '@mui/material'
import React, {useState} from 'react'






export default function Test({onsc, text}){

    const [state, setState] = useState('...')


    function handleChange(e){
        const newState = e.target.value
        setState(newState)
        onsc(newState)
    }


    return(
        <>
        <TextField onChange={handleChange} />
        <p style={{backgroundColor: "#959"}} >{state}</p>
        <p style={{backgroundColor: "#A5C"}} >{text}</p>

        </>
    )
}