import React from 'react'
import ChannelsMain from '../components/channels/ChannelsMain'




export default function HomePage(props){


    return(
        <>
            <ChannelsMain />
        </>
    )
}

