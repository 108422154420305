import React, {useState} from 'react'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {sendDataToServer} from '../functions';



export default function TableData({data, delUpdate}){
    const [copen, setCopen] = useState({id:0, state:false});



    const handleClick = (id) =>{
        setCopen({id:id, state:true})
    }


    const handleConfirm = () => {
        sendDataToServer({op:"delete", table:"streams", where: "id="+copen.id}).then(
            delUpdate()
        )
        setCopen({id:0, state:false})
    }



    return(
        <>
        <TableContainer component={Paper}>
        <Table  size="small">
            <TableHead>
            <TableRow>
                <TableCell>#id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Input</TableCell>
                <TableCell>Output</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                data.map((v, k) => 
                    <TableRow key={k}>
                        <TableCell>{v.id}</TableCell>
                        <TableCell>{v.name}</TableCell>
                        <TableCell>{v.input}</TableCell>
                        <TableCell>{v.output}</TableCell>
                        <TableCell><Button onClick={() => handleClick(v.id)}><DeleteOutlinedIcon /></Button></TableCell>
                    </TableRow>
                )
            }
            </TableBody>
        </Table>
        </TableContainer>

        <Dialog open={copen.state} onClose={setCopen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
            <DialogContent><DialogContentText id="alert-dialog-description">
                Are you sure delete this stream?
            </DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Yes</Button>
                <Button onClick={() => setCopen({id:0, state:false})}>No</Button>
            </DialogActions>
        </Dialog>
        </>

    )

}