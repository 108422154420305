import React, {useState} from 'react'
import Test from '../components/Test'


export default function HelpPage(props){

    const [parentState, setParentState] = useState('')

    function handleStateChange(newState){
        setParentState(newState)
    }







    return(
        <>
            <p>{parentState}</p>
            <Test text="это пропс" onsc={handleStateChange} />
        </>
    )
}