import React from 'react'



export default function SettingsPage(props){

    return(
        <>
            Настройки системы...
        </>
    )
}