import React, {useState, useEffect} from 'react'
import StreamsForm from '../components/streams/StreamsForm'
import TableData from '../components/streams/TableData'
import { sendDataToServer } from '../components/functions'





export default function StreamPage(props){

    const [data, setData] = useState([])
    const [fu, setFu] = useState([]) // flag update DATA

    useEffect(() => {
        sendDataToServer({op: "select", table: "streams", cols: "*", where: "id>0 ORDER BY time_create DESC"}).then((r) => {
            setData(r)
            //console.log(r)
        })
    }, [fu])



    function flagUpdate(){
        setFu(fu+1)
    }



    return(
        <>
            <div style={{'display':"flex", justifyContent: "flex-end", paddingRight: "20px"}}>
                <StreamsForm mode="add" flagUpdate={flagUpdate} /> 
            </div>
            <TableData data={data} delUpdate={flagUpdate} />


            {/*<StreamsForm mode="edit" /> */}
        </>
    )
}