import { Button } from '@mui/material'
import React from 'react'
import {sendDataToServer} from '../components/functions'


export default function OsExec({cmd, value, flagUpdate}){

    //const [out, setOut] = useState("")


    function handleClick(){
        sendDataToServer({'op': "exec", 'cmd': cmd})
        setTimeout(function(){
            flagUpdate()
        },500)
    }

    return (

        <>
            <Button onClick={handleClick}>{value ? value : "exec"}</Button>
        </>
    )
}