import React, { useEffect, useState } from 'react'
import {sendDataToServer, convUnixDate} from '../components/functions'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Typography} from '@mui/material'
import SourceIcon from '@mui/icons-material/Source';
import ModalForm from '../components/ModalForm';
import ModalDelete from '../components/ModalDelete';
import OsExec from '../components/OsExec'






export default function SourcesPage(){

    
    const [src, setSrc] = useState([])
    const [fu, setFu] = useState(0) // flag update DATA

  // eslint-disable-next-line
    const [dform, setDform] = useState({
        url: '',
        descr: '',
        status: 1,
        update: 0
    })


    useEffect(() => {
        sendDataToServer({'op': "select", 'table': "sources", 'cols':"*", where: "id>0 ORDER BY update DESC"})
        .then(r => {
            setSrc(r)
            //console.log(fu);
        })
    }, [fu]) 


    function flagUpdate(){
        setFu(fu+1)
    }






    function handleAdd(formData){
        sendDataToServer({op: "insert", table: "sources", ...formData})
        .then(r => {
            setDform({...formData})
            flagUpdate()
        })
    }

    function handleEdit(formData){
        sendDataToServer({op: "update", table: "sources", where: "id="+formData.id, ...formData})
        .then(r => {
            setDform({...formData})
            flagUpdate()
        })
    }





    return(
        <>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography variant="h5"><SourceIcon /> EPG Sources</Typography>
                <ModalForm mode="add" data={dform} title="common everybody" onClick={handleAdd} />
                <ModalDelete 
                    sql={[
                        {op: "truncate", table: "names"},
                        {op: "truncate", table: "program"},
                        {op: "update", table: "sources", cntprg: "0/0", update: '0', where: "id>0"}
                    ]}
                    flagUpdate={flagUpdate}
                    value="clear db"
                />
            </div>
            <TableContainer component={Paper}>
                <Table  size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>#id</TableCell>
                        <TableCell>url</TableCell>
                        <TableCell>descr</TableCell>
                        <TableCell>cntprg</TableCell>
                        <TableCell>update</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        src.map((v, k) => 
                            <TableRow key={k} className="nopadding-td">
                                <TableCell>{v.id}</TableCell>
                                <TableCell><a href={v.url} target="_blank" rel="noreferrer">{v.url}</a></TableCell>
                                <TableCell>{v.descr}</TableCell>
                                <TableCell>{v.cntprg}</TableCell>
                                <TableCell>{convUnixDate(v.update)}</TableCell>
                                <TableCell style={{textAlign:"center"}}>{v.status}</TableCell>
                                <TableCell>
                                    <OsExec cmd={"screen -dmS epg"+v.id+" /var/www/epgapi.com/includes/epgSrcUpdate.php "+v.id} value="UPD" flagUpdate={flagUpdate} />
                                    <ModalForm mode="edit" data={v} onClick={handleEdit} />
                                    <ModalDelete 
                                        sql={[
                                            {'op': "delete", 'table': "sources", where: "id="+v.id},
                                            {'op': "delete", 'table': "names", where: "srcid="+v.id},
                                            {'op': "delete", 'table': "program", where: "srcid="+v.id},
                                        ]}
                                        flagUpdate={flagUpdate}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


