import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Slide} from "@mui/material"
import {sendDataToServer} from '../components/functions'





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





export default function ModalDelete({sql, flagUpdate, value}){
    const [open, setOpen] = useState(false)


    function handleOpen(){
        setOpen(true)
    }

    function handleClose(){
        setOpen(false)
    }


    function handleAgree(){
        sql.map((v) => {
            return sendDataToServer(v)
        })
        setOpen(false)
        flagUpdate()
    }



    return (

        <>
        
            <Button onClick={handleOpen}>{value ? value : "Del"}</Button>
        

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Really delete this?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Once this record is deleted, it will be impossible to restore it!.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree}>Agree</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}