import React from 'react'
import { TextareaAutosize, Button } from '@mui/material'
import { sendDataToServer } from '../components/functions';






export default function TunnelPage(props){

    return(
        <>
            <TextareaAutosize placeholder="Ввведи чтонить" />
            <hr />
            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "create", 'table': "testtt", 'userw':"text", 'name':"text", 'age':"integer"})
                .then(r => console.log(r))
            }>CREATE</Button>

            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "insert", 'table': "testtt", 'userw':"text123", 'name':"text321", 'age' : "now()"})
                .then(r => console.log(r))
            }>INSERT</Button>

            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "select", 'table': "testtt", 'cols':"*", 'where': "id > 1"})
                .then(r => console.log(r))
            }>SELECT</Button>

            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "update", 'table': "testtt", age:55, name:'qwe\'rqw\'er', 'where': "id = 5"})
                .then(r => console.log(r))
            }>UPDATE</Button>

            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "drop", 'table': "testtt"})
                .then(r => console.log(r))
            }>DROP</Button>

            <Button style={{marginRight : "20px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "delete", 'table': "testtt"})
                .then(r => console.log(r))
            }>DELETE</Button>

            <Button style={{marginRight : "10px"}} variant="contained" onClick={() =>
              sendDataToServer({'op': "exec", 'cmd': "ifconfig"})
                .then(r => {
                  console.log(r)
                  alert(r.out)
                })
            }>OS.EXEC</Button>

        </>
    )
}

