import React from 'react'



export default function FilePage(props){

    return( 
        <>
            Это главная страница FILEPAGE
        </>
    )
}